import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

export const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  width: 75%;
  /* max-width: 600px; */
  padding-top: ${getMargin('base')};
  margin-bottom: ${getMargin('block')};
`;
