import React from 'react';

import { StyledSubTitle } from './styled';

export interface SubTitleProps {
  subTitle: string;
}

export const SubTitle: React.FC<SubTitleProps> = ({ subTitle }) => {
  return <StyledSubTitle>{subTitle}</StyledSubTitle>;
};
