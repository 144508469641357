import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: ${getMargin('base')};
  }
`;

export const StyledForm = styled.form`
  margin: ${getMargin('base')};
  > *:not(:last-child) {
    margin-bottom: ${getMargin('base')};
  }
`;
