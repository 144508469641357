import React from 'react';

import { Aside, MainContent, Wrapper } from './styled';

interface MainLayoutProps {
  children: React.ReactNode;
  aside: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, aside }) => {
  return (
    <Wrapper>
      <MainContent>{children}</MainContent>
      <Aside>{aside}</Aside>
    </Wrapper>
  );
};
