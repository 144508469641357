import React from 'react';

import { Section } from '../../atoms/Section';
import { SectionHeader } from '../SectionHeader';

import { Wrapper } from './styled';

export interface PageSectionProps {
  title: string;
  subTitle?: string;
  backgroundColor?: string;
  children: React.ReactNode;
  withSection?: boolean;
}

export const PageSection: React.FC<PageSectionProps> = ({
  title,
  subTitle,
  backgroundColor,
  children,
  withSection,
}) => {
  if (withSection) {
    return (
      <Wrapper backgroundColor={backgroundColor}>
        <Section>
          <SectionHeader title={title} subTitle={subTitle} />
          {children}
        </Section>
      </Wrapper>
    );
  }
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <SectionHeader title={title} subTitle={subTitle} />
      {children}
    </Wrapper>
  );
};
