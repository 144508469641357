import React from 'react';
import { CallOutline, MailOutline, PersonOutline } from 'react-ionicons';

import { Title } from '../../atoms/Title/Title';

import { ContactWrapper, Info } from './styled';

export interface ContactInfoProps {
  name: string;
  phone: string;
  mail: string;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ name, phone, mail }) => {
  return (
    <ContactWrapper>
      <Title title="Contact" />
      <Info>
        <PersonOutline /> {name}
      </Info>
      <Info>
        <CallOutline /> {phone}
      </Info>
      <Info>
        <MailOutline /> {mail}
      </Info>
    </ContactWrapper>
  );
};
