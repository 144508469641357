import styled from 'styled-components';

interface WrapperProps {
  backgroundColor?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || undefined};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
