import React from 'react';

import { InputGroup } from '../../molecules/InputGroup';

import { InputRow, StyledForm } from './styled';

export function ContactForm() {
  return (
    <StyledForm name="contact" method="POST" data-netlify="true">
      <input type="hidden" name="contact" value="Contact Form" />
      <InputRow>
        <InputGroup type="text" id="name" label="Nom" />
        <InputGroup type="email" id="mail" label="Mail" />
      </InputRow>
      <InputRow>
        <InputGroup type="tel" id="phone" label="Téléphone" />
        <InputGroup type="select" id="subject" label="Sujet" />
      </InputRow>
      <InputRow>
        <InputGroup type="date" id="date" label="Date de l'événement" />
        <InputGroup type="text" id="place" label="Lieu de l'événement" />
      </InputRow>
      <InputGroup type="textArea" id="message" label="Votre demande" />
      <button type="submit">Send</button>
    </StyledForm>
  );
}
