import styled from 'styled-components';

export const StyledSubTitle = styled.p`
  text-transform: none;
  font-family: 'Cardo', sans-serif;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
`;
