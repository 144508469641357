import styled from 'styled-components';

export const StyledInput = styled.input`
  padding: 8px;
  width: 100%;
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledLabel = styled.label``;

export const StyledError = styled.p`
  color: red;
`;
