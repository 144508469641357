import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../../components/Layout';
import { ContactInfo } from '../../components/molecules/ContactInfo';
import { PageSection } from '../../components/molecules/PageSection';
import { ContactForm } from '../../components/organisms/ContactForm/ContactForm';
import { MainLayout } from '../../components/templates/MainLayout/MainLayout';

interface ContactQuery {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
        presentation: {
          title: string;
          subtitle: string;
        };
        contactInfo: {
          title: string;
          name: string;
          phone: string;
          mail: string;
        };
      };
    };
  };
}

const ContactPage = ({ data }: ContactQuery) => {
  const { presentation, contactInfo } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <main>
        {presentation && (
          <PageSection
            title={presentation.title}
            subTitle={presentation.subtitle}
            withSection
            children={undefined}
          />
        )}
        <MainLayout
          aside={
            <ContactInfo
              name={contactInfo.name}
              phone={contactInfo.phone}
              mail={contactInfo.mail}
            />
          }
        >
          {contactInfo && (
            <PageSection withSection title={contactInfo.title}>
              <ContactForm />
            </PageSection>
          )}
        </MainLayout>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        description
        presentation {
          title
          subtitle
        }
        contactInfo {
          title
          name
          phone
          mail
        }
      }
    }
  }
`;

export default ContactPage;
