import styled from 'styled-components';

import { Device, Responsive } from '../../../styles/theme/responsive';

// Footer styled
export const Aside = styled.aside`
  width: 30%;
  @media ${Responsive.lessThan(Device.tablet)} {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  width: 70%;

  @media ${Responsive.lessThan(Device.tablet)} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  @media ${Responsive.lessThan(Device.tablet)} {
    flex-direction: column;
  }
`;
