import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

export const ContactWrapper = styled.ul``;

export const Info = styled.li`
  display: list-item;
  list-style-type: none;
  justify-content: space-between;
  margin-bottom: ${getMargin('base')};
`;
