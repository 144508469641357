import React from 'react';

import { SubTitle } from '../../atoms/SubTitle';
import { Title } from '../../atoms/Title';

import { Wrapper } from './styled';

export interface SectionHeaderProps {
  title: string;
  subTitle?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <Title title={title} />
      {subTitle && <SubTitle subTitle={subTitle} />}
    </Wrapper>
  );
};
